<template>
  <div class="transaction-details">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header">
          <div class="card-title">Подробности платежа</div>
        </div>
        <div v-if="dataLoading" class="card-body">
          <data-loading :loading="dataLoading"/>
        </div>
        <div v-if="!dataLoading" class="card-body">
          <div class="form-group">
            <button @click="$router.back()" class="btn btn-link" type="button">
              <i class="bi-arrow-return-left"></i>
              Обратно
            </button>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8">
              <dl>
                <dt>Дата и время</dt>
                <dd>
                  <date-format :date="transaction.date" format="DD.MM.YYYY hh:mm:ss"/>
                </dd>
                <dt>Назначение платежа</dt>
                <dd>{{ transaction.description }}</dd>
                <dt>Сумма платежа</dt>
                <dd>
                  <money :money="transaction.money" :decimal="true"/>
                </dd>
                <dt>Тип платежа</dt>
                <dd>{{ paymentTypeLabel }}</dd>
                <dt>Тип транзакции</dt>
                <dd>{{ transactionTypePurpose }}{{this.transaction.buybackOfferUuid ? ' (Откуплен по байбек)' : ''}}</dd>
                <dt>Статус</dt>
                <dd :class="{ 'text-info': isPending, 'text-danger': isCanceled }">
                  {{ transactionStatusLabel }}
                </dd>
              </dl>
            </div>
            <div class="col-12 col-lg-4 text-right">
              <button v-if="canCancelInvestment"
                      @click="cancelInvestment"
                      type="button"
                      class="btn btn-outline-success"
              >
                Отменить инвестицию
              </button>
              <button v-if="canCancelWithdraw"
                      @click="cancelWithdraw"
                      type="button"
                      class="btn btn-outline-success"
              >
                Отменить вывод средств
              </button>
            </div>
          </div>
        </div>
        <div v-if="!dataLoading" class="card-body">
          <div class="h6">Операции по платежу</div>
          <div class="row d-none d-sm-flex">
            <div class="col-sm-4 col-lg-2 small text-muted">Дата</div>
            <div class="col-sm-8 col-lg-4 small text-muted">Тип операции</div>
            <div class="col-lg-6 d-none d-lg-block small text-muted">Описание</div>
          </div>
          <div v-for="operation in transaction.operations" class="row mt-3">
            <div class="col-12 col-sm-4 col-lg-2">
              <date-format :date="operation.date" format="DD.MM.YYYY hh:mm:ss"/>
            </div>
            <div class="col-12 col-sm-8 col-lg-4">
              {{ operationTypeLabel(operation.type) }}
            </div>
            <div class="col-12 col-lg-6">
              {{ operation.description }}
              <span v-if="!operation.description" class="d-none d-lg-inline">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { DataLoading, DateFormat, Money, Processing } from "@/components/common";
import { TransactionStatus, TransactionType } from "@/utils/api-client";
import { createInvestmentManager, createTransactionProvider, createWithdrawManager } from "@/utils/di";
import { DashboardEventType } from "../../../enums/DashboardEventType";
import operationTypeLabels from "../data/operationTypeLabels";
import paymentTypeLabels from "../data/paymentTypeLabels";
import transactionStatusLabels from "../data/transactionStatusLabels";
import transactionTypePurposes from "../data/transactionTypePurposes";

export default {
  name: "TransactionDetails",
  components: {
    DataLoading,
    DateFormat,
    Money,
    Processing
  },
  data() {
    return {
      dataLoading: false,
      processing: false,
      transaction: {}
    };
  },
  computed: {
    transactionTypePurpose() {
      return transactionTypePurposes[this.transaction.transactionType] || this.transaction.transactionType;
    },
    paymentTypeLabel() {
      return paymentTypeLabels[this.transaction.paymentType] || this.transaction.paymentType;
    },
    transactionStatusLabel() {
      return transactionStatusLabels[this.transaction.status] || this.transaction.status;
    },
    isPending() {
      return this.transaction.status === TransactionStatus.PENDING;
    },
    isCanceled() {
      return this.transaction.status === TransactionStatus.CANCELED;
    },
    canCancelInvestment() {
      return this.transaction.canCancel && this.transaction.transactionType === TransactionType.INVESTMENT;
    },
    canCancelWithdraw() {
      return this.transaction.canCancel && this.transaction.transactionType === TransactionType.WITHDRAW;
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.dataLoading = true;
      try {
        const provider = await createTransactionProvider();
        this.transaction = await provider.getOne(this.$route.params.uuid);
        console.log(this.transaction);
      } finally {
        this.dataLoading = false;
      }
    },
    cancelInvestment() {
      this.$confirm("Вы действительно хотите отменить инвестицию?", async () => {
        this.processing = true;
        try {
          const manager = await createInvestmentManager();
          await manager.cancel(this.transaction.investmentUuid);
          this.$emit("dashboardEvent", DashboardEventType.InvestmentCanceled);
          await this.loadData();
        } finally {
          this.processing = false;
        }
      });
    },
    cancelWithdraw() {
      this.$confirm("Вы действительно хотите отменить заявку на вывод средств?", async () => {
        this.processing = true;
        try {
          const manager = await createWithdrawManager();
          await manager.cancel(this.transaction.uuid);
          this.$emit("dashboardEvent", DashboardEventType.WithdrawCanceled);
          await this.loadData();
        } finally {
          this.processing = false;
        }
      });
    },
    operationTypeLabel(operationType) {
      return operationTypeLabels[operationType] || operationType;
    }
  }
}
</script>

<style lang="scss">
.transaction-details {
  .card-body {
    dl {
      dt, dd {
        flex: 0 0 100%;
        max-width: 100%;
      }

      dt {
        margin-top: 0.5rem;
      }

      dd {
        margin-bottom: 0.5rem;
      }

      @media (min-width: 576px) { // Bootstrap SM
        dt {
          flex: 0 0 35%;
          max-width: 35%;
          margin-bottom: 0.5rem;
        }

        dd {
          flex: 0 0 65%;
          max-width: 65%;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>
