import { OperationType } from "@/utils/api-client";

export default {
  [OperationType.REGISTER]: "Регистрация средств",
  [OperationType.REMOVE_REGISTERED]: "Отмена зарегистрированных средств",
  [OperationType.ENROLL]: "Начисление зарегистрированных средств",
  [OperationType.RESERVE]: "Резервирование средств",
  [OperationType.REFUND_RESERVED]: "Возврат зарезервированых средств",
  [OperationType.CHARGE]: "Списание зарезервированых средств"
};
